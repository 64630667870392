import React, { useState, useRef } from 'react';
import { graphql } from 'gatsby';

import SEO from '../components/seo';
import { Layout } from '../layout/Layout';
import { SearchBanner, Pagination, PublicationCard, SubscriptionBanner } from '../components/common';
import useDragScroll from 'use-drag-scroll';
import { viewMoreButton, allButton } from '../translations/buttons';

export const query = graphql`
  query PublicacionesQuery {
    site {
      siteMetadata {
        cmsUrl
      }
    }
    directus {
      publications_page_translations {
        title
        search_placeholder
        bottom_banner_text
        bottom_banner_button
        subscription_form_link
        languages_code {
          code
        }
      }
      publicaciones(sort: "-fecha", limit: -1) {
        id
        slug
        titulo
        titulo_es
        titulo_en
        titulo_pt
        titulo_fr
        mensaje
        mensaje_es
        mensaje_en
        mensaje_pt
        mensaje_fr
        region
        categoria
        fecha
        imagen {
          id
        }
      }
    }
    directus_system {
      region_field: fields_by_name(field: "region", collection: "publicaciones") {
        meta {
          options
        }
      }
      categoria_field: fields_by_name(field: "categoria", collection: "publicaciones") {
        meta {
          options
        }
      }
      settings {
        translation_strings
      }
    }
  }
`;

const Publicaciones = ({ data, pageContext }) => {
  const { publicaciones, publications_page_translations } = data.directus;
  const { title, search_placeholder, bottom_banner_text, bottom_banner_button, subscription_form_link } =
    publications_page_translations.filter(t => t.languages_code.code === pageContext.languageCode)[0];

  // Get translations of every region
  const { choices: regionChoices } = data.directus_system.region_field.meta.options;
  const { choices: categoriesChoices } = data.directus_system.categoria_field.meta.options;
  const { translation_strings } = data.directus_system.settings;

  const regions = {};
  regionChoices.forEach(choice => {
    const choiceTranslationKey = choice.text.split(':')[1];
    regions[choice.value] = translation_strings.filter(({ key }) => key === choiceTranslationKey)[0]['translations'];
  });

  const categories = {};
  categoriesChoices.forEach(choice => {
    const choiceTranslationKey = choice.text.split(':')[1];
    categories[choice.value] = translation_strings.filter(({ key }) => key === choiceTranslationKey)[0]['translations'];
  });

  // Filter Region
  const [regionFilter, setRegionFilter] = useState('Todo');
  const regionFilteredPublications = publicaciones.filter(publication => {
    return regionFilter === 'Todo' ? true : publication.region.includes(regionFilter);
  });

  // Filter Categories
  const [categoryFilter, setCategoryFilter] = useState('Todos');
  const categoriesFilteredPublications = regionFilteredPublications.filter(publication => {
    return categoryFilter === 'Todos' ? true : publication.categoria === categoryFilter;
  });

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 7;
  const pageCount = Math.ceil(categoriesFilteredPublications.length / postsPerPage);
  const pageIndex = (currentPage - 1) * postsPerPage;
  const currentPublications = categoriesFilteredPublications.slice(pageIndex, pageIndex + postsPerPage);

  const cmsURL = data.site.siteMetadata.cmsUrl;

  // Scroll on tabs
  const selectSliderRef = useRef();
  useDragScroll({
    sliderRef: selectSliderRef,
  });

  return (
    <Layout pageId="publicaciones" currentLanguage={pageContext.languageCode}>
      <SearchBanner
        title={title}
        searchPlaceholder={search_placeholder}
        articles={publicaciones}
        isNew={false}
        currentLanguage={pageContext.languageCode}
        basePath={pageContext.basePath}
      />

      <section className="articles">
        <div className="container">
          <div className="row">
            <div className="col-md-11 mx-auto articles__selector">
              <div ref={selectSliderRef} className="select-slider no-scrollbar">
                <button
                  className={`btn ${regionFilter === 'Todo' ? 'active' : ''}`}
                  onClick={() => {
                    setRegionFilter('Todo');
                    setCurrentPage(1);
                  }}
                >
                  {allButton[pageContext.languageCode]}
                </button>
                {Object.keys(regions).map(key => (
                  <button
                    key={key}
                    className={`btn ${regionFilter === key ? 'active' : ''}`}
                    onClick={() => {
                      setRegionFilter(key);
                      setCurrentPage(1);
                    }}
                  >
                    {regions[key][pageContext.languageCode]}
                  </button>
                ))}
              </div>
              <select
                defaultValue={categoryFilter}
                onChange={e => {
                  setCategoryFilter(e.target.value);
                  setCurrentPage(1);
                }}
              >
                {Object.keys(categories).map(key => (
                  <option key={key} value={key}>
                    {categories[key][pageContext.languageCode]}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-md-11 mx-auto articles__list">
              {currentPublications.map(article => {
                const titleTranslations = {
                  'en-US': article.titulo_en,
                  'es-ES': article.titulo_es,
                  'fr-FR': article.titulo_fr,
                  'pt-BR': article.titulo_pt,
                };
                const messageTranslations = {
                  'en-US': article.mensaje_en,
                  'es-ES': article.mensaje_es,
                  'fr-FR': article.mensaje_fr,
                  'pt-BR': article.mensaje_pt,
                };

                return (
                  <PublicationCard
                    key={article.id}
                    title={titleTranslations[pageContext.languageCode] || article.titulo}
                    description={article.summary}
                    mensaje={messageTranslations[pageContext.languageCode] || article.mensaje}
                    date={article.fecha}
                    tag={categories[article.categoria][pageContext.languageCode]}
                    image={article.imagen ? `${cmsURL}/assets/${article.imagen.id}` : null}
                    to={`${pageContext.basePath}/publications/${article.slug}`}
                    buttonText={viewMoreButton[pageContext.languageCode]}
                    currentLanguage={pageContext.languageCode}
                  />
                );
              })}
            </div>

            <Pagination
              totalCount={categoriesFilteredPublications.length}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageCount={pageCount}
              pageSize={postsPerPage}
            />
          </div>
        </div>
      </section>

      <SubscriptionBanner
        innerText={bottom_banner_text}
        buttonText={bottom_banner_button}
        link={subscription_form_link}
      />
    </Layout>
  );
};

export default Publicaciones;

export const Head = () => <SEO title="Publications - MicroRate" />;
